'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { env } from 'utils/envManager'

interface UpdateHomeUrlProps {
	readonly newUrl: string | undefined
}

export const UpdateHomeUrl = ({ newUrl }: UpdateHomeUrlProps) => {
	const searchParams = useSearchParams()

	useEffect(() => {
		const url = new URL(`${newUrl}`, env.NEXT_PUBLIC_SITE_BASE_URL)
		url.search = new URLSearchParams(searchParams as any).toString()

		window.history.replaceState(null, '', `${url.pathname}${url.search}`)
	}, [])

	return null
}
