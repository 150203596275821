import { useCallback, useEffect, useLayoutEffect, useRef } from 'react'

import { getContainer } from '../../utils/getContainer'

const VIEWPORT_PERCENTAGE = 0.2
const QUICKLY = 60 // px/s
const ONE_SECOND = 1000
const DECIMAL = 10
const BACKDROP_OPACITY = '--backdrop-opacity'

function now() {
	return Math.floor(Date.now() / ONE_SECOND)
}

const isDraggingOnScrollableContent = (target: HTMLElement) => {
	const found = target?.closest('[data-type="content"]')
	if (!found) {
		return false
	}
	return found.clientHeight !== found.scrollHeight
}

export function useDraggableBottomSheet({
	dialogRef,
	draggableRef,
	opened,
	setOpened,
}: {
	dialogRef: React.RefObject<HTMLDialogElement>
	draggableRef: React.RefObject<HTMLDivElement>
	opened: boolean
	setOpened: (opened: boolean) => void
}) {
	const initialState = useRef({
		offset: 0,
		height: 0,
	})

	const isDragging = useCallback(() => !!initialState.current.height, [])

	/**
	 * When bottom sheet content is scrolled only can be dragged down using the header
	 */
	const isContentScrolling = useCallback(
		(target: HTMLElement) =>
			draggableRef.current?.querySelector('[data-scrolling]') &&
			!target.closest('[data-dialog-header]'),
		[draggableRef]
	)

	const onDragging: (e: TouchEvent) => void = useCallback(
		(e: TouchEvent) => {
			// When we are dragging on a scrollable content we should not prevent the default behavior
			// This is possible because content have a css property overscroll-behavior: contain
			if (
				e.cancelable &&
				!isDraggingOnScrollableContent(e.target as HTMLElement)
			) {
				e.preventDefault()
			}
			const draggable = draggableRef.current
			if (
				isDragging() &&
				!isContentScrolling(e.target as HTMLElement) &&
				draggable
			) {
				const { offset, height } = initialState.current
				const topLimit = window.innerHeight - height
				const top = Math.max(e.touches[0].clientY - offset, topLimit)
				const percentage = 1 - ((topLimit - top) * -1) / height
				if (dialogRef.current) {
					dialogRef.current.style.setProperty(BACKDROP_OPACITY, `${percentage}`)
				}
				draggable.dataset.distance = `${(topLimit - top) * -1}`
				getContainer(dialogRef.current)?.style.setProperty('top', `${top}px`)
			}
		},
		[dialogRef, draggableRef, isContentScrolling, isDragging]
	)

	const onDrop: (e: TouchEvent) => void = useCallback(
		(e: TouchEvent) => {
			if (isDragging()) {
				const { height } = initialState.current
				const draggable = draggableRef.current
				if (!isContentScrolling(e.target as HTMLElement) && draggable) {
					const originalTimestamp = Number.parseInt(
						draggable?.dataset.timeStamp ?? '0',
						DECIMAL
					)
					const seconds = originalTimestamp - now() || 1
					const distance = Number.parseInt(
						draggable?.dataset.distance ?? '0',
						DECIMAL
					)
					const velocity = distance / seconds
					const shouldCloseHeight = window.innerHeight * VIEWPORT_PERCENTAGE
					if (
						distance >= shouldCloseHeight ||
						(velocity > 0 && velocity <= QUICKLY)
					) {
						setOpened(false)
						draggable.dataset.distance = '-1'
					} else {
						getContainer(dialogRef.current)?.style.removeProperty('top')
						dialogRef.current?.style.removeProperty(BACKDROP_OPACITY)
					}
				}
				// Restore the original height when user is scrolling the content while dragging down to close
				if (isContentScrolling(e.target as HTMLElement) && draggable) {
					const topLimit = window.innerHeight - height
					draggable.dataset.distance = `${topLimit}`
					getContainer(dialogRef.current)?.style.setProperty(
						'top',
						`${topLimit}px`
					)
					dialogRef.current?.style.removeProperty(BACKDROP_OPACITY)
				}
			}
		},
		[dialogRef, draggableRef, isContentScrolling, isDragging, setOpened]
	)
	const onTouchStart = useCallback(
		(e: TouchEvent) => {
			if (!isContentScrolling(e.target as HTMLElement)) {
				const draggable = draggableRef.current
				const height = dialogRef.current?.children[0].clientHeight ?? 0
				if (draggable) {
					draggable.dataset.timeStamp = now().toString()
				}

				const bottom = (window.innerHeight - height - e.touches[0].clientY) * -1
				if (draggableRef.current?.scrollTop === 0) {
					draggableRef.current.dataset.distance = '0'
					initialState.current = {
						offset: bottom,
						height,
					}
				}
			}
		},
		[dialogRef, draggableRef, isContentScrolling]
	)

	useEffect(() => {
		if (!opened) {
			getContainer(dialogRef.current)?.style.removeProperty('top')
		}

		if (opened && dialogRef.current) {
			// Begins to listen touch events to prevent the default behavior on iOS
			dialogRef.current.addEventListener('touchmove', onDragging, {
				passive: false,
			})
			dialogRef.current.addEventListener('touchend', onDrop)
			initialState.current = {
				offset: 0,
				height: 0,
			}
		}

		return () => {
			if (dialogRef.current) {
				dialogRef.current.removeEventListener('touchmove', onDragging)
				dialogRef.current.removeEventListener('touchend', onDrop)
			}
		}
	}, [dialogRef, onDragging, onDrop, opened])

	useLayoutEffect(() => {
		if (opened && draggableRef.current) {
			dialogRef.current?.style.removeProperty(BACKDROP_OPACITY)
			draggableRef.current.addEventListener('touchstart', onTouchStart, {
				capture: false,
			})
			draggableRef.current.addEventListener('scroll', (event) => {
				event?.preventDefault()
			})
		}
		return () => {
			dialogRef.current?.style.removeProperty(BACKDROP_OPACITY)
			if (draggableRef.current) {
				draggableRef.current.removeEventListener('touchstart', onTouchStart)
			}
		}
	}, [dialogRef, draggableRef, onTouchStart, opened])
}
