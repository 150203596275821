'use client'

import { Dialog, type DialogAPI, apiFactory } from 'fukku/Dialog/Dialog'
import { useClosingAnimation } from 'fukku/Dialog/hooks/useClosingAnimation'
import type { DialogProps } from 'fukku/Dialog/types/props'
import { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { cx } from 'utils/cx'

import { DialogContextProvider, DialogType } from '../hooks/useDialogContext'
import { useDraggableBottomSheet } from './hooks/useDraggableBottomSheet'

import styles from './BottomSheet.module.scss'

export function BottomSheetComponent(
	{
		children,
		preventClose = false,
		title,
		onClose,
		className,
		'data-testid': dataTestId,
	}: DialogProps,
	ref: React.Ref<DialogAPI>
) {
	const { isTouchable } = useResponsive()
	const dialogRef = useRef<HTMLDialogElement>(null)
	const draggableRef = useRef<HTMLDivElement>(null)

	const { opened, closing, setOpened } = useClosingAnimation(dialogRef)

	useDraggableBottomSheet({
		dialogRef,
		draggableRef,
		opened,
		setOpened,
	})

	const api = useMemo(
		() =>
			apiFactory(
				setOpened,
				Boolean(dialogRef.current?.open),
				dialogRef.current ?? null
			),
		[setOpened]
	)

	useImperativeHandle(ref, () => api, [api])

	return (
		<DialogContextProvider
			type={DialogType.BottomSheet}
			api={api}
			preventClose={preventClose}
		>
			<Dialog
				title={title}
				className={cx(styles.bottom, closing && styles.closing, className)}
				ref={dialogRef}
				preventClose={preventClose}
				setOpened={setOpened}
				opened={opened}
				onClose={onClose}
				data-testid={dataTestId}
			>
				<div
					ref={draggableRef}
					data-draggable
					className={cx(styles.draggable, isTouchable && styles.touchable)}
				>
					{children}
				</div>
			</Dialog>
		</DialogContextProvider>
	)
}

export const BottomSheet = forwardRef(BottomSheetComponent)
