'use client'

import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import { DATA_TEST_ID } from 'back-in-stock/constants'
import { BackInStockBody } from 'back-in-stock/src/components/BackInStockModal/BackInStockBody/BackInStockBody'
import { useBackInStockModalContext } from 'back-in-stock/src/hooks/useBackInStockModalContext/useBackInStockModalContext'
import { BottomSheet } from 'fukku/Dialog/BottomSheet/BottomSheet'
import { Content } from 'fukku/Dialog/Content/Content'
import type { DialogAPI } from 'fukku/Dialog/Dialog'
import { Header } from 'fukku/Dialog/Header/Header'
import { Modal } from 'fukku/Dialog/Modal/Modal'
import { BackInStockLegalPolicy } from 'legal/components/policies/BackInStockLegalPolicy/BackInStockLegalPolicy'
import { useEffect, useMemo, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'

import { GenericEvents } from '../../constants/analytics'

export function BackInStockModal() {
	const modalRef = useRef<DialogAPI>(null)
	const { isTouchable } = useResponsive()
	const { isOpen, closeProductSubscription } = useBackInStockModalContext()
	const { backInStockModalProps } = useBackInStockModalContext()

	useEffect(() => {
		if (isOpen && modalRef.current) {
			sendGenericEvent(GenericEvents.NOTIFY_ME_SHOW, {
				product_id: backInStockModalProps?.productId,
				color: backInStockModalProps?.productColorId,
				size: backInStockModalProps?.productSizeId,
			})
			modalRef.current.show()
		}

		if (!isOpen && modalRef.current) {
			modalRef.current.close()
		}
	}, [isOpen])

	const ModalComponent = useMemo(
		() => (isTouchable ? BottomSheet : Modal),
		[isTouchable]
	)

	return (
		<ModalComponent
			ref={modalRef}
			title='back-in-stock-modal'
			data-testid={DATA_TEST_ID.modal}
			onClose={closeProductSubscription}
		>
			<Header />
			<Content>
				<BackInStockBody />
				<BackInStockLegalPolicy />
			</Content>
		</ModalComponent>
	)
}
